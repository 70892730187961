import { h, render } from 'preact';
import Widget from './widget';
import {defaultConfiguration} from './default-configuration';

if (window.attachEvent) {
    window.attachEvent('onload', injectChat);
} else {
    window.addEventListener('load', injectChat, false);
}

function injectChat() {
    if (!window.server) {
        console.error('Please set window.server');
    } else {
        let root = document.createElement('div');
        root.id = 'matrixLiveChatRoot';
        document.getElementsByTagName('body')[0].appendChild(root);
        const server = window.server;
        const iFrameSrc = server + '/chat.html';
        const host = window.location.host || 'unknown-host';

        render(
            <Widget host={host}
                    isMobile={window.screen.width < 500}
                    iFrameSrc={iFrameSrc}
                    conf={defaultConfiguration}
            />,
            root
        );

        try {
            const request = new XMLHttpRequest();
            request.open('POST', server + '/usage-start?host=' + host);
            request.send();
        } catch (e) { /* Fail silently */ }
    }
}
